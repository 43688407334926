import { ReactNode } from 'react';
import { AuthUserContextProvider } from './AuthUserContextProvider';
import { IFrameContextProvider } from './IFrameContextProvider';
import { PendingUserContextProvider } from './PendingUserContextProvider';
import { WidgetPartsPricesContextProvider } from './WidgetPartsPricesContextProvider';
export const WidgetMessagesProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return <>
      <IFrameContextProvider data-sentry-element="IFrameContextProvider" data-sentry-source-file="WidgetMessagesProvider.tsx">
        <AuthUserContextProvider data-sentry-element="AuthUserContextProvider" data-sentry-source-file="WidgetMessagesProvider.tsx">
          <PendingUserContextProvider data-sentry-element="PendingUserContextProvider" data-sentry-source-file="WidgetMessagesProvider.tsx">
            <WidgetPartsPricesContextProvider data-sentry-element="WidgetPartsPricesContextProvider" data-sentry-source-file="WidgetMessagesProvider.tsx">
              <>{children}</>
            </WidgetPartsPricesContextProvider>
          </PendingUserContextProvider>
        </AuthUserContextProvider>
      </IFrameContextProvider>
    </>;
};