'use client';

import { useEffect } from 'react';
import { useAuthUserContext } from '@/contexts/AuthUserContextProvider';
import { useIFrameContext } from '@/contexts/IFrameContextProvider';
import { usePendingUserContext } from '@/contexts/PendingUserContextProvider';
import { useWidgetPartsPrices } from '@/contexts/WidgetPartsPricesContextProvider';
import { MessageFromWidgetEvent, MessagesFromWidget } from '@/shared/SalesPilotConstants';
export const useWidgetMessages = () => {
  const {
    parts,
    setParts
  } = useWidgetPartsPrices();
  const {
    setAuthUser
  } = useAuthUserContext();
  const {
    setIsIframeLoaded
  } = useIFrameContext();
  const {
    setPendingUser
  } = usePendingUserContext();

  // Event Listener for Iframe Loaded
  useEffect(() => {
    const eventListener = (e: MessageFromWidgetEvent) => {
      const action = e.data.action;
      switch (action) {
        case MessagesFromWidget.IFRAME_LOADED:
          setIsIframeLoaded(true);
          break;
        case MessagesFromWidget.MULTIPLE_PARTS_PRICE_RESPONSE:
          {
            const {
              payload: {
                pricesAndOriginalPrices,
                authUser: newAuthUser
              }
            } = e.data;
            const newPartsMap = pricesAndOriginalPrices.reduce((acc, {
              partID,
              ...data
            }) => {
              acc[Number(partID)] = {
                status: 'success',
                data
              };
              return acc;
            }, parts);
            setParts(newPartsMap);
            setAuthUser(newAuthUser);
          }
          break;
        case MessagesFromWidget.USER_LOGGED_IN:
        case MessagesFromWidget.USER_SIGNED_UP:
        case MessagesFromWidget.USER_LOGGED_OUT:
          {
            setParts({});
            setAuthUser(null);
            setIsIframeLoaded(false);
          }
          break;
        case MessagesFromWidget.INITIALIZED:
          {
            setAuthUser(e.data.payload.authUser);
            setPendingUser(e.data.payload.pendingUser);
          }
          break;
        case MessagesFromWidget.SET_AUTH_USER:
          {
            setAuthUser(e.data.payload);
          }
          break;
        case MessagesFromWidget.SET_PENDING_USER:
          {
            setPendingUser(e.data.payload);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', eventListener);
    return () => window.removeEventListener('message', eventListener);
  }, [setParts, setAuthUser, setIsIframeLoaded, setPendingUser, parts]);
};