'use client';

import { createContext, PropsWithChildren } from 'react';
import { tenantAliasObs } from '@/global/tenantStore/tenantStore';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
type TenantStoreContextType = {
  appInfo: GetAppResponse;
  tenantConfig: TenantConfig;
};
export const TenantStoreContext = createContext<TenantStoreContextType | null>(null);
export const TenantStoreContextProvider = ({
  children,
  appInfo,
  tenantAlias,
  tenantConfig
}: PropsWithChildren<{
  appInfo: GetAppResponse;
  tenantAlias: string;
  tenantConfig: TenantConfig;
}>) => {
  // Makes the tenantAlias available to all client and server side _components.
  tenantAliasObs.set(tenantAlias);
  return <TenantStoreContext.Provider value={{
    appInfo: appInfo,
    tenantConfig
  }} data-sentry-element="unknown" data-sentry-component="TenantStoreContextProvider" data-sentry-source-file="TenantStoreContext.tsx">
      {children}
    </TenantStoreContext.Provider>;
};